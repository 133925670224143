import React from 'react';
import GlobalStyle from '../assets/global.style';
import SimpleHeader from '../components/SimpleHeader-comp/simpleheader';
import TermsConditionsContent from '../components/TermsConditionsContent-comp/termsconditionscontent';
import Footer from '../components/Footer-comp/footer';

function TermsConditions() {
  return (
    <div className="App">
      <GlobalStyle />
      <SimpleHeader />
      <TermsConditionsContent />
      <Footer />
    </div>
  );
}

export default TermsConditions;
