import React from 'react';
import { ConfigContext } from '../ConfigProvider/ConfigProvider';
import CanadaPrivacyPolicy from './canadaPrivacyPolicy';
import UsaPrivacyPolicy from './usaPrivacyPolicy';
import PrivacyPolicyContentWrapper from './privacypolicycontent.style';

class PrivacyPolicyContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { company, address, country } = this.context;
    return (
      <PrivacyPolicyContentWrapper className="privacy-content">
        {country === 'CAN' ? (
          <CanadaPrivacyPolicy />
        ) : (
          <UsaPrivacyPolicy company={company} address={address} />
        )}
      </PrivacyPolicyContentWrapper>
    );
  }
}

PrivacyPolicyContent.contextType = ConfigContext;

export default PrivacyPolicyContent;
