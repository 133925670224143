import React from 'react';
import { Grid, Container } from 'semantic-ui-react';
import { ConfigContext } from '../ConfigProvider/ConfigProvider';
import SimpleHeaderWrapper from './simpleheader.style';

class SimpleHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { logo, company, url } = this.context;
    return (
      <SimpleHeaderWrapper>
        <header className='simple-header'>
          <Container>
            <Grid columns='two'>
              <Grid.Row>
                <Grid.Column>
                  <a href={url}>
                    <img src={logo} alt={company} className='logo' />
                  </a>
                </Grid.Column>

                <Grid.Column className='contact-number'>
                  <h3>
                    Free Consultation
                    <span>844.508.4495</span>
                  </h3>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </header>
      </SimpleHeaderWrapper>
    );
  }
}

SimpleHeader.contextType = ConfigContext;

export default SimpleHeader;
