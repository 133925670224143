import React from 'react';
import lendersLogo from '../../assets/images/lal-logo.png';
import dcomlogo from '../../assets/images/logo-debt.com-whitetext.png';
import dcalogo from '../../assets/images/debtca-logo.png';
import lendersphoneScreen from '../../assets/images/mobile-screen-lenders.png';
import dcomphoneScreen from '../../assets/images/mobile-image-dcom.png';
import dcaphoneScreen from '../../assets/images/mobile-image-dca.png';
import GoDaddyLogo from '../../assets/images/godaddySecure.png';
import BBBLogo from '../../assets/images/bbb-aplus-logo.png';
import UWayLogo from '../../assets/images/United-Way-logo.png';
import TPilotLogo from '../../assets/images/trustpilot-logo.png';

const domainsList = ['debt.com', 'lendersandloans.com', 'localhost', 'loancomparison.debt.ca'];
let currentDomain = 'defaultHostname';

const domain = window.location.hostname;

for (let i = 0; i < domainsList.length; i++) {
  if (domain.indexOf(domainsList[i]) !== -1) {
    currentDomain = domainsList[i];
    break;
  }
}

const isStagingUrl = domain.indexOf('staging') !== -1;

const variants = {
  'lendersandloans.com': {
    country: 'USA',
    logo: lendersLogo,
    logoLink: 'https://lendersandloans.com',
    url: `https://${isStagingUrl ? 'staging.' : ''}lendersandloans.com`,
    company: 'LendersandLoans.com',
    copyright: '© 2020 LendersandLoans.com®, LLC. All Rights Reserved.',
    address: '6360 NW 5TH Way #302, Fort Lauderdale, Florida 33309 USA',
    phoneScreen: lendersphoneScreen,
    credibility: [
      {
        id: 'GoDaddyLogo',
        logo: GoDaddyLogo,
        link:
          'https://seal.godaddy.com/verifySeal?sealID=VttdGalb6RNnHGweH1ZPwYmmYt3XSbYeXM2Jk90v88Poa2JxPmkvASdRAOnA',
        alt: 'Godaddy',
        target: '_blank',
        stylesClass: 'logo-styles',
      },
    ],
  },
  localhost2: {
    country: 'CAN',
    logo: dcalogo,
    url: 'https://www.debt.ca/?utm_source=loancomparison&utm_campaign=loancomparelogo',
    company: 'Debt.ca',
    copyright: '© 2020 Debt.ca All Rights Reserved',
    address: '5769 W. Sunrise Blvd. Plantation, FL 33313',
    phoneScreen: dcaphoneScreen,
    credibility: [
      {
        id: 'BBBLogo',
        logo: BBBLogo,
        link:
          'https://www.bbb.org/us/fl/plantation/profile/credit-services/debtcom-llc-0633-90080459',
        alt: 'BBBLogo',
        target: '_blank',
        stylesClass: 'logo-styles',
      },
      { id: 'UWayLogo', logo: UWayLogo, link: '', alt: 'UWayLogo', target: '' },
      {
        id: 'GoDaddyLogo',
        logo: GoDaddyLogo,
        link:
          'https://seal.godaddy.com/verifySeal?sealID=VttdGalb6RNnHGweH1ZPwYmmYt3XSbYeXM2Jk90v88Poa2JxPmkvASdRAOnA',
        alt: 'Godaddy',
        target: '_blank',
        stylesClass: 'logo-styles',
      },
      {
        id: 'TPilotLogo',
        logo: TPilotLogo,
        link: 'https://www.debt.com/reviews/',
        alt: 'TPilotLogo',
        target: '_blank',
        stylesClass: 'logo-styles',
      },
    ],
  },
  'debt.com': {
    country: 'USA',
    logo: dcomlogo,
    url: 'https://www.debt.com/?utm_source=loancomparison&utm_campaign=loancomparelogo',
    company: 'Debt.com',
    copyright: '© 2020 Debt.com, LLC. All Rights Reserved',
    address: '5769 W. Sunrise Blvd. Plantation, FL 33313',
    phoneScreen: dcomphoneScreen,
    credibility: [
      {
        id: 'BBBLogo',
        logo: BBBLogo,
        link:
          'https://www.bbb.org/us/fl/plantation/profile/credit-services/debtcom-llc-0633-90080459',
        alt: 'BBBLogo',
        target: '_blank',
        stylesClass: 'logo-styles',
      },
      { id: 'UWayLogo', logo: UWayLogo, link: '', alt: 'UWayLogo', target: '' },
      {
        id: 'GoDaddyLogo',
        logo: GoDaddyLogo,
        link:
          'https://seal.godaddy.com/verifySeal?sealID=VttdGalb6RNnHGweH1ZPwYmmYt3XSbYeXM2Jk90v88Poa2JxPmkvASdRAOnA',
        alt: 'Godaddy',
        target: '_blank',
        stylesClass: 'logo-styles',
      },
      {
        id: 'TPilotLogo',
        logo: TPilotLogo,
        link: 'https://www.debt.com/reviews/',
        alt: 'TPilotLogo',
        target: '_blank',
        stylesClass: 'logo-styles',
      },
    ],
  },
  'debt.ca': {
    country: 'CAN',
    logo: dcalogo,
    url: 'https://www.debt.ca/?utm_source=loancomparison&utm_campaign=loancomparelogo',
    company: 'Debt.ca',
    copyright: '© 2020 Debt.ca All Rights Reserved',
    address: '5769 W. Sunrise Blvd. Plantation, FL 33313',
    phoneScreen: dcaphoneScreen,
    credibility: [
      {
        id: 'BBBLogo',
        logo: BBBLogo,
        link:
          'https://www.bbb.org/us/fl/plantation/profile/credit-services/debtcom-llc-0633-90080459',
        alt: 'BBBLogo',
        target: '_blank',
        stylesClass: 'logo-styles',
      },
      { id: 'UWayLogo', logo: UWayLogo, link: '', alt: 'UWayLogo', target: '' },
      {
        id: 'GoDaddyLogo',
        logo: GoDaddyLogo,
        link:
          'https://seal.godaddy.com/verifySeal?sealID=VttdGalb6RNnHGweH1ZPwYmmYt3XSbYeXM2Jk90v88Poa2JxPmkvASdRAOnA',
        alt: 'Godaddy',
        target: '_blank',
        stylesClass: 'logo-styles',
      },
      {
        id: 'TPilotLogo',
        logo: TPilotLogo,
        link: 'https://www.debt.com/reviews/',
        alt: 'TPilotLogo',
        target: '_blank',
        stylesClass: 'logo-styles',
      },
    ],
  },
  'loancomparison.debt.ca': {
    country: 'CAN',
    logo: dcalogo,
    url: 'https://www.debt.ca/?utm_source=loancomparison&utm_campaign=loancomparelogo',
    company: 'Debt.ca',
    copyright: 'Copyright © 2020. LoanComparison.ca - All Rights Reserved',
    address: 'Debt.ca 595 Burrard St Vancouver BC V7X1S8',
    phoneScreen: dcaphoneScreen,
    credibility: [
      {
        id: 'GoDaddyLogo',
        logo: GoDaddyLogo,
        link:
          'https://seal.godaddy.com/verifySeal?sealID=VttdGalb6RNnHGweH1ZPwYmmYt3XSbYeXM2Jk90v88Poa2JxPmkvASdRAOnA',
        alt: 'Godaddy',
        target: '_blank',
        stylesClass: 'logo-styles',
      },
    ],
  },
  defaultHostname: {
    logo: '',
    url: '',
    company: '',
    copyright: '',
    address: '',
    phoneScreen: '',
    credibility: [
      {
        id: 'GoDaddyLogo',
        logo: GoDaddyLogo,
        link:
          'https://seal.godaddy.com/verifySeal?sealID=VttdGalb6RNnHGweH1ZPwYmmYt3XSbYeXM2Jk90v88Poa2JxPmkvASdRAOnA',
        alt: 'Godaddy',
        target: '_blank',
        stylesClass: 'logo-styles',
      },
    ],
  },
};

const config = variants[currentDomain];

const ConfigContext = React.createContext({});

export { ConfigContext, config };
