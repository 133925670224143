import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

  html {
    scroll-behavior: smooth;
  }
  body {
    font-family: 'Open Sans', sans-serif;
    font-weight:100;
    font-size:18px;
  }

  h1, h2, h3, h4, h5 {
    font-family: 'Open Sans', sans-serif;
    font-weight:100;
  }

  h2 {
    font-size:2.5em;
  }   

  .ui.primary.button, .ui.primary.buttons .button {
    background-color: #6da852;
    color: #fff;
    text-shadow: none;
    background-image: none;
    font-family:'Open Sans',sans-serif;
  }
  

  @media (max-width: 1200px) {
    h2 {
        font-size:2.3em;
    }   
  } 

  @media (max-width: 991px) {
    h2 {
        font-size:2.0em;
    } 
  }

  @media (max-width: 767px) {
    h2 {
        font-size:1.8em;
    } 
  }
`

export default GlobalStyle;
