import styled from 'styled-components'
const AdvertiserDisclosureContentWrapper = styled.div`
    &.advertiserdisclosure-content {
        padding:50px 0;
        p {
            font-size:16px;
        }
        h1 {
            font-weight:400;
            font-size:2.8em;
        } 

        h2 {
            font-size:2em;
        }
    }
   
    
`;

export default AdvertiserDisclosureContentWrapper;