import styled from 'styled-components';

const PrivacyPolicyContentWrapper = styled.div`
  &.privacy-content {
    padding: 50px 0;
    p {
      font-size: 16px;
    }
    h3 {
      font-weight: 400;
    }
    h1 {
      font-weight: 400;
      font-size: 2.8em;
    }
  }
`;

export default PrivacyPolicyContentWrapper;
