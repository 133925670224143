import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { ConfigContext } from '../ConfigProvider/ConfigProvider';
import GeneralCTAWrapper from './generalcta.style';

class GeneralCTA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { company, phoneScreen } = this.context;
    return (
      <div className='App'>
        <GeneralCTAWrapper className='generalCTA'>
          <Container>
            <Grid columns='two'>
              <Grid.Row>
                <Grid.Column width={9}>
                  <h2>Find the Best Loan to Fit Your Needs and Goals</h2>
                  <p>
                    With rates starting at 4.99% and loans up to $40,000, you can get the loan that
                    you need in as little as 1 day. No waiting and wondering if you’ll get approved.
                    Compare offers from multiple lenders in minutes and apply for the money you need
                    without delay!
                  </p>
                  <a href='#root' className='ui primary button'>
                    Get Started
                  </a>
                </Grid.Column>

                <Grid.Column width={7}>
                  <img src={phoneScreen} alt={company} className='logo' />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </GeneralCTAWrapper>
      </div>
    );
  }
}

GeneralCTA.contextType = ConfigContext;

export default GeneralCTA;
