import React from 'react';
import GlobalStyle from '../assets/global.style';
import SimpleHeader from '../components/SimpleHeader-comp/simpleheader';
import PartnersContent from '../components/PartnersContent-comp/partnerscontent';
import Footer from '../components/Footer-comp/footer';


function Partners() {
  return (

      <div className="App">
        <GlobalStyle/>
        <SimpleHeader/>
        <PartnersContent/>
        <Footer/>
      </div>
    
  );
}

export default Partners