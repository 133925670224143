import React, { Component } from 'react';
import { Grid, Container } from 'semantic-ui-react';
import IframeResizer from 'iframe-resizer-react';
import { ConfigContext } from '../ConfigProvider/ConfigProvider';
import HeaderWrapper from './header.style';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { logo, url, company } = this.context;
    return (
      <div className="App">
        <HeaderWrapper>
          <header className="main-header">
            <Container>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <a href={url}>
                      <img src={logo} alt={company} className="logo" />
                    </a>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <h1>Compare Personal Loan Offers in Minutes!</h1>

              <div>
                <IframeResizer
                  log
                  src="https://loanscanada.ca/app/iframe?affiliate_id=209&product_names=personal_loan&bgcolor=%233092f1&hide_logo=1&preselected_product=personal_loan"
                  title="loanscanada"
                  id="loanscanada_iframe"
                  frameBorder="0"
                  scrolling="no"
                  style={{ width: '1px', minWidth: '100%' }}
                />
                <a
                  href="https://loanscanada.ca?affiliate_id=209"
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  title="Visit LoansCanada.ca"
                >
                  <small>Visit LoansCanada.ca For More Services</small>
                </a>
              </div>
            </Container>
          </header>
        </HeaderWrapper>
      </div>
    );
  }
}

Header.contextType = ConfigContext;

export default Header;
