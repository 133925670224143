import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ConfigContext, config } from './components/ConfigProvider/ConfigProvider';
import Homepage from './pages/homepage';
import PrivacyPolicy from './pages/privacy-policy';
import TermsConditions from './pages/terms-conditions';
import DoNotSell from './pages/do-not-sell';
import AdvertiserDisclosure from './pages/advertiser-discloser';
import Partners from './pages/partners';

function App() {
  return (
    <ConfigContext.Provider value={config}>
      <div className="App">
        <Router>
          <Route exact path="/" component={Homepage} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms-conditions" component={TermsConditions} />
          <Route path="/do-not-sell" component={DoNotSell} />
          <Route path="/advertiser-disclosure" component={AdvertiserDisclosure} />
          <Route path="/partners" component={Partners} />
        </Router>
      </div>
    </ConfigContext.Provider>
  );
}

export default App;
