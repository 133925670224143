import styled from 'styled-components';

const CredibilityLogosStyles = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 30px auto 0;
  img {
    width: 100px;
  }
  @media (max-width: 835px) {
    justify-content: space-evenly;
    width: 94%;
    img {
      width: 75px;
    }
  }
`;

export default CredibilityLogosStyles;
