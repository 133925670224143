import React from 'react';
import { ConfigContext } from '../ConfigProvider/ConfigProvider';
import TermsConditionsContentWrapper from './termsconditionscontent.style';
import UsaTerms from './usaTermsandConditions';
import CanadaTerms from './canadaTermsandConditions';

class TermsConditionsContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { company, country } = this.context;
    return (
      <TermsConditionsContentWrapper className="terms-content">
        {country === 'CAN' ? <CanadaTerms company={company} /> : <UsaTerms company={company} />}
      </TermsConditionsContentWrapper>
    );
  }
}

TermsConditionsContent.contextType = ConfigContext;

export default TermsConditionsContent;
