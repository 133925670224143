import styled from 'styled-components';

const HeaderWrapper = styled.div`
  header {
    background: #3092f1;
    text-align: center;
    padding: 30px 0 50px;
    transition: all 0.3s ease-in-out;
    .logo {
      max-width: 100%;
      height: auto;
      width: 200px;
    }

    h1 {
      color: white;
      text-align: center;
      font-size: 3em;
      width: 70%;
      margin: 35px auto;
      z-index: 8888;
      position: relative;
    }

    .contact-number {
      text-align: right;
      color: white;

      h3 {
        font-size: 1.5em;
        margin-top: 10px;
        span {
          display: block;
          font-size: 1.3em;
        }
      }

      button.ui.button {
        background: transparent;
        color: white;
        padding: 0;
        margin: 0;
        font-family: 'Open Sans';
      }
    }
    a small {
      color: white;
    }

    .credibility-logos {
      margin: 50px auto 0;
      text-align: center;

      img {
        width: 150px;
      }
    }
  }

  @media (max-width: 1200px) {
    header {
      h1 {
        font-size: 2.7em;
        width: 85%;
      }

      .contact-number {
        h3 {
          font-size: 1.4em;
          span {
            font-size: 1.28em;
          }
        }
      }

      #loanscanada_iframe {
      }
    }
  }

  @media (max-width: 991px) {
    header {
      padding: 30px 0 40px;

      .logo {
        width: 175px;
      }

      h1 {
        font-size: 2.4em;
      }

      .contact-number {
        h3 {
          font-size: 1.2em;
          span {
            font-size: 1.28em;
          }
        }
      }
    }
  }

  @media (max-width: 835px) {
    header {
      padding: 20px 0 20px;

      .logo {
        width: 140px;
      }

      h1 {
        font-size: 1.8em;
        width: 100%;
        margin: 20px 0 0;
      }

      .contact-number {
        h3 {
          font-size: 1em;
          span {
            font-size: 1.28em;
          }
        }
      }

      .credibility-logos {
        display: none;
      }

      #loanscanada_iframe {
      }
    }
  }

  @media (max-width: 500px) {
    header {
      .logo {
        width: 130px;
      }

      h1 {
        font-size: 1.55em;
        width: 100%;
        margin: 20px 0 0;
      }

      .contact-number {
        h3 {
          font-size: 0.9em;
          span {
            font-size: 1.28em;
          }
        }
      }

      .credibility-logos {
        display: none;
      }
    }
  }
`;

export default HeaderWrapper;
