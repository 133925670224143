import styled from 'styled-components'
const TermsConditionsContentWrapper = styled.div`
    &.terms-content {
        padding:50px 0;
        p {
            font-size:16px;
        }
        h3 {
            font-weight:400;
        }
        h1 {
            font-weight:400;
            font-size:2.8em;
        } 
    }
   
    
`;

export default TermsConditionsContentWrapper;