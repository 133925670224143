import React from 'react';
import { Container } from 'semantic-ui-react';
import PartnersContentWrapper from './partnerscontent.style';

function PartnersContent() {
  return (
    <PartnersContentWrapper className='partners-content'>
      <Container>
        <h1>Partners</h1>
        <p>Last updated December 2017</p>
        <p>Alliance West Mortgage</p>
        <p>Automobile Consumer Services</p>
        <p>Avant</p>
        <p>Challenge Student Debt</p>
        <p>CommonBond</p>
        <p>Community First Bank</p>
        <p>Community Mortgage LLC</p>
        <p>Consolidated Credit Counseling</p>
        <p>Consolidated Credit Solutions</p>
        <p>Debt.com</p>
        <p>E*TRADE Financial</p>
        <p>First Down Funding</p>
        <p>IT Media</p>
        <p>Lighthouse Finance</p>
        <p>Quinstreet</p>
        <p>Student Loan Help Center</p>
        <p>T3</p>
        <p>WebBanks</p>
        <p>Zeroparallel</p>
      </Container>
    </PartnersContentWrapper>
  );
}

export default PartnersContent;
