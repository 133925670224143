import React from 'react';
import { Responsive } from 'semantic-ui-react';
import { CarouselProvider, Slider } from 'pure-react-carousel';
import { ConfigContext } from '../ConfigProvider/ConfigProvider';
import MobileFormWrapper from './mobileform.style';
import CustomCardSlide from '../CarouselComponents/CustomCardSlide';
import CustomDotGroup from '../CarouselComponents/CustomDotGroup';
import CredibilityLogos from '../CredibilityLogos/CredibilityLogos';

class MobileForm extends React.Component {
  state = {};

  render() {
    const { credibility } = this.context;
    return (
      <div className='App'>
        <MobileFormWrapper id='mobile-form-container'>
          <h3>What are you looking to do with this loan?</h3>
          <div className='arrow-up' />
          <CarouselProvider naturalSlideWidth={1} naturalSlideHeight={1.25} totalSlides={3}>
            <Slider>
              <CustomCardSlide index={0}>
                <a className='loan-type' href='loan-application?loanPurpose=Debt%20Consolidation'>
                  <i className='fal fa-layer-group' />
                  Debt Consolidation
                </a>

                <a className='loan-type' href='loan-application?loanPurpose=Household%20Expense'>
                  <i className='fal fa-home-lg-alt' />
                  Household Expense
                </a>

                <a
                  className='loan-type'
                  href='loan-application?loanPurpose=Credit%20Card%20Consolidation'
                >
                  <i className='fal fa-credit-card' />
                  Credit Card Consolidation
                </a>

                <a className='loan-type' href='loan-application?loanPurpose=Home%20Improvement'>
                  <i className='fal fa-wrench' />
                  Home Improvement
                </a>

                <a className='loan-type' href='loan-application?loanPurpose=Auto'>
                  <i className='fal fa-car' />
                  Auto
                </a>

                <a className='loan-type' href='loan-application?loanPurpose=Personal'>
                  <i className='fal fa-map-marker-question' />
                  Other
                </a>
              </CustomCardSlide>
              <CustomCardSlide index={1}>
                <a className='loan-type' href='loan-application?loanPurpose=Taxes'>
                  <i className='fal fa-university' />
                  Taxes
                </a>

                <a
                  className='loan-type'
                  href='loan-application?loanPurpose=Special%20Event%20Expenses'
                >
                  <i className='fal fa-calendar-alt' />
                  Special Event Expenses
                </a>

                <a className='loan-type' href='loan-application?loanPurpose=Boat'>
                  <i className='fal fa-anchor' />
                  Boat
                </a>

                {/* <a className='loan-type' href='loan-application?loanPurpose=Major%20Purchase'>
                  <i className='fal fa-suitcase' />
                  Business
                </a> */}
                <a className='loan-type' href='loan-application?loanPurpose=Medical/Dental'>
                  <i className='fal fa-notes-medical' />
                  Medical/Dental
                </a>`

                <a className='loan-type' href='loan-application?loanPurpose=RV'>
                  <i className='fal fa-rv' />
                  RV
                </a>

                <a className='loan-type' href='loan-application?loanPurpose=Vacation'>
                  <i className='fal fa-island-tropical' />
                  Vacation
                </a>
              </CustomCardSlide>
              <CustomCardSlide index={2}>
                <a className='loan-type' href='loan-application?loanPurpose=Major%20Purchase'>
                  <i className='fal fa-shopping-cart' />
                  Major Purchase
                </a>

                <a className='loan-type' href='loan-application?loanPurpose=Motorcycle'>
                  <i className='fal fa-motorcycle' />
                  Motorcycle
                </a>

                

                <a className='loan-type' href='loan-application?loanPurpose=Moving/Relocation'>
                  <i className='fal fa-truck-couch' />
                  Moving & Relocation
                </a>

                <a className='loan-type' href='loan-application?loanPurpose=Green'>
                  <i className='fal fa-leaf' />
                  Green
                </a>

                <a className='loan-type' href='loan-application?loanPurpose=Baby/Adoption'>
                  <i className='fal fa-baby' />
                  Baby/Adoption
                </a>
              </CustomCardSlide>
            </Slider>
            <CustomDotGroup slides={3} />
          </CarouselProvider>
        </MobileFormWrapper>
        <Responsive as='div' maxWidth={835}>
          <CredibilityLogos logos={credibility} />
        </Responsive>
      </div>
    );
  }
}

MobileForm.contextType = ConfigContext;

export default MobileForm;
