import React from 'react';
import { Image } from 'semantic-ui-react';
import CredibilityLogosStyles from './CredibilityLogos.styles';

const CredibilityLogos = ({ logos }) => (
  <CredibilityLogosStyles>
    {logos.map(logo => (
      <Image
        key={logo.id}
        src={logo.logo}
        alt={logo.alt}
        href={logo.link}
        target={logo.target}
        className={logo.stylesClass}
      />
    ))}
  </CredibilityLogosStyles>
);

export default CredibilityLogos;
