import React from 'react';
import { Container } from 'semantic-ui-react';
import { ConfigContext } from '../ConfigProvider/ConfigProvider';
import FooterWrapper from './footer.style';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { logo, company, copyright, address } = this.context;
    return (
      <div className="App">
        <FooterWrapper>
          <footer>
            <Container>
              <img src={logo} alt={company} className="logo" />
              <p>
                <a href="/privacy-policy" target="_blank">
                  Privacy Policy
                </a>
                |
                <a href="/terms-conditions" target="_blank">
                  Terms & Conditions
                </a>
              </p>
              <p>{copyright}</p>
              <p>{address}</p>
              <p className="disclaimer">
                {`Disclaimer: All loans are subject to credit and underwriting approval. ${company} provides a loan search platform and comparison website, and is not a lender. ${company} only works with third party providers that adhere to Canadian laws and regulations. Loans may range from $500-$35,000 with terms from 4 months to 60 Months or longer. APRs may range from 2.99% to 46.96% and will depend on our partner's assessment of your credit profile. For example, on a $500 loan paid monthly over 9 months, a person will pay $81.15 per month for a total of $730.35 over the course of the entire loan period. This amount may include a partner's optional loan protection policy. In the event of a missed payment an insufficient funds fee of around 45$ may be charged (dependent on the lender). In the case of a default on your loan your payment plan will be terminated and different collection methods will be employed to collect your remaining balance. Outstanding debts will be pursued to the full extent of the law. Our lenders employ fair collection practices.`}
              </p>
            </Container>
          </footer>
        </FooterWrapper>
      </div>
    );
  }
}

Footer.contextType = ConfigContext;

export default Footer;
