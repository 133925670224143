import React from 'react';
import PropTypes from 'prop-types';
import { Slide } from 'pure-react-carousel';

const CustomCardSlide = ({ index, children }) => <Slide index={index}>{children}</Slide>;

CustomCardSlide.propTypes = {
  index: PropTypes.number.isRequired
};

export default CustomCardSlide;
