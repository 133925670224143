import React from 'react';
import HowItWorksWrapper from './howitworks.style';

import { Container } from 'semantic-ui-react';

function HowItWorks() {
  return (
    <div className="App">
        <HowItWorksWrapper className="how-it-works-section"> 
          <Container >

            <h2>How it Works</h2>
            <ul>
                <li> <div className="circle">1</div>
                <p>Complete the form 
                <span>in 2 minutes.</span></p>
                </li>
                <li><div className="circle">2</div>
                <p>Get matched with <span>top lenders.</span></p>
                </li>
                <li><div className="circle">3</div>
                <p>Compare<span>rates and terms.</span></p>
                </li>
                <li><div className="circle">4</div>
                <p>Choose the <span>best loan for you.</span></p>
                </li>
            </ul> 
          </Container>
        </HowItWorksWrapper>
      
    </div>
  );
}

export default HowItWorks;