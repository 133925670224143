import styled from 'styled-components'
const SimpleHeaderWrapper = styled.div`
    header {
        background: rgb(42,53,61);
        background: -moz-linear-gradient(172deg, rgba(42,53,61,1) 0%, rgba(36,111,135,1) 100%);
        background: -webkit-linear-gradient(172deg, rgba(42,53,61,1) 0%, rgba(36,111,135,1) 100%);
        background: linear-gradient(172deg, rgba(42,53,61,1) 0%, rgba(36,111,135,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2a353d",endColorstr="#246f87",GradientType=1);
        padding:30px 0 30px;

        .logo {
            max-width:100%;
            height:auto;
            width:200px;
        }

        h1 {
            color:white;
            text-align:center;
            font-size:3em;
            width:70%;
            margin:35px auto;
        }

        .contact-number {
            text-align:right;
            color: white;

            h3 {
                font-size:1.5em;
                margin-top:20px;
                span {
                    display:block;
                    font-size:1.3em;
                }
            }

            button.ui.button {
                background: transparent;
                color: white;
                padding: 0;
                margin: 0;
                font-family: 'Open Sans';
            }
            
        }
    }

    @media (max-width: 1200px) {
        header {

            h1 {
                font-size:2.7em;
                width:85%;
            }

            .contact-number {

                h3 {
                    font-size:1.4em;
                    span {
                        font-size:1.28em;
                    }
                }
                
            }
        }
    }

    @media (max-width: 991px) {
        header {
            padding:30px 0 40px;

            .logo {
                width:175px;
            }

            h1 {
                font-size:2.4em;
            }

            .contact-number {

                h3 {
                    font-size:1.2em;
                    span {
                        font-size:1.28em;
                    }
                }
            }
        }
    }

    @media (max-width: 835px) {
        header {
            padding:20px 0 20px;

            .logo {
                width:140px;
            }

            h1 {
                font-size:1.8em;
                width:100%;
                margin: 20px 0 0;
            }

            .contact-number { 
                h3 {
                    font-size:1.0em;
                    span {
                        font-size:1.28em;
                    }
                }
            }

        }
    }

    @media (max-width: 500px) {
        header {

            .logo {
                width:130px;
            }

            h1 {
                font-size:1.55em;
                width:100%;
                margin: 20px 0 0;
            }

            .contact-number {
                h3 {
                    font-size:.9em;
                    span {
                        font-size:1.28em;
                    }
                }
            }

        }
    }
    
`;

export default SimpleHeaderWrapper;