import styled from 'styled-components'
const FooterWrapper=styled.div`
    footer {
        background-color:#2a353d;
        text-align:center;
        padding:30px 0;

        .logo {
            width:180px;
            margin-bottom:30px;
        }

        p {
            color:white;
            font-size:.9em;
            color:#afafaf;
            a {
                color:white;
                padding:0 10px;

                &:hover {
                    color:#44accd;
                }
            }

            &.disclaimer {
                font-size:12px;
            }
        }
    }
`;

export default FooterWrapper;