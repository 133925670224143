import styled from 'styled-components'

const MobileFormWrapper=styled.div`

    @media (min-width: 836px) {
        display:none;
    }
    &#mobile-form-container {
        margin:25px;
        text-align:center;

        h3 {
            font-weight:400;
            font-size:1.2em;
        }

        .arrow-up {
            width: 0; 
            height: 0; 
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 15px solid #ededed;
            margin: 0 auto;
          }
        .carousel {
            
            .carousel__slider {
                ul {
                    
                    li {
                        background-color:#ededed;
                        padding:15px;
                        border-radius:4px;
                        margin-bottom:15px;
                        padding-bottom: 245px !important;
                        
                        .carousel__inner-slide {
                            /*display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;*/
                            padding:20px;
                            height:auto;

                            a.loan-type {
                                background-color:white;
                                text-align:center;
                                border-radius:4px;
                                /*flex: 0 1 31%;*/
                                border:1px solid #ededed;
                                display:block;
                                padding:15px;
                                margin-bottom:15px;
                                color:#2a353d;
                                -webkit-transition: all .1s;
                                transition: all .1s ease;
                                margin: 0 5px 5px;
                                float:left;
                                min-height:107px;
                                width:31%;
                                line-height:21px;
                                font-size:16px;

                                .svg-inline--fa {
                                    color:#44accd;
                                    display:block;
                                    font-size:1.8em;
                                    margin-bottom: 5px;
                                    margin-left: auto;
                                    margin-right: auto;

                                    &.fa-car, 
                                    &.fa-map-marker-question, 
                                    &.fa-university, 
                                    &.fa-anchor, 
                                    &.fa-suitcase, 
                                    &.fa-rv, 
                                    &.fa-island-tropical, 
                                    &.fa-motorcycle, 
                                    &.fa-notes-medical, 
                                    &.fa-leaf, 
                                    &.fa-baby {
                                        margin-top:12px;
                                    }
                                }
                                

                                &:hover {
                                    background-color:#44accd;
                                    color:white;

                                    .svg-inline--fa {
                                        color:white;
                                    }
                                }
                            }
                        }
                    }
                }
    
            }
    
        }
    }
    @media (min-width: 789px) {
        &#mobile-form-container {
    
            .carousel {
                
                .carousel__slider {
                    ul {
                        
                        li {
                            
                            .carousel__inner-slide {
    
                                a.loan-type {
                                    .svg-inline--fa {
    
                                        &.fa-credit-card {
                                            margin-top:12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
        
                }
        
            }
        }
    } 

    @media (max-width: 755px) {
        &#mobile-form-container {
    
            .carousel {
                
                .carousel__slider {
                    ul {
                        
                        li {
                            
                            .carousel__inner-slide {
    
                                a.loan-type {
                                    line-height:20px;
                                }
                            }
                        }
                    }
        
                }
        
            }
        }
    } 

    @media (min-width: 663px) {
        &#mobile-form-container {
    
            .carousel {
                
                .carousel__slider {
                    ul {
                        
                        li {
                            
                            .carousel__inner-slide {
    
                                a.loan-type {
                                    .svg-inline--fa {
    
                                        &.fa-layer-group, 
                                        &.fa-home-lg-alt, 
                                        &.fa-wrench, 
                                        &.fa-shopping-cart {
                                            margin-top:12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
        
                }
        
            }
        }
    } 

    @media (max-width: 518px) {
        &#mobile-form-container {
    
            .carousel {
                
                .carousel__slider {
                    ul {
                        
                        li {
                            padding-bottom: 235px !important;
                            
                            .carousel__inner-slide {
                                /*display: flex;
                                flex-wrap: wrap;
                                justify-content: space-between;*/
                                padding:15px;
    
                                a.loan-type {
                                    margin: 0 3px 5px;
                                    width:31.6%;
                                    line-height:20px;
                                    font-size:15px;
                                }
                            }
                        }
                    }
        
                }
        
            }
        }
    } 



    @media (max-width: 482px) {
        &#mobile-form-container {
    
            .carousel {
                
                .carousel__slider {
                    ul {
                        
                        li {
                            
                            .carousel__inner-slide {
    
                                a.loan-type {
                                    padding:8px 5px;
                                    min-height:104px;

                                    .svg-inline--fa.fal {
                                        padding-top:6px;
                                    }
                                }
                            }
                        }
                    }
        
                }
        
            }
        }
    } 

    @media (max-width: 425px) {
        &#mobile-form-container {
    
            .carousel {
                
                .carousel__slider {
                    ul {
                        
                        li {
                            padding-bottom: 352px !important;
                            
                            .carousel__inner-slide {
    
                                a.loan-type {
                                    width:48%;
                                    padding:15px;
                                    font-size:16px;

                                    .svg-inline--fa {
    
                                        &.fa-car, 
                                        &.fa-map-marker-question, 
                                        &.fa-university, 
                                        &.fa-anchor, 
                                        &.fa-suitcase, 
                                        &.fa-rv, 
                                        &.fa-island-tropical, 
                                        &.fa-motorcycle, 
                                        &.fa-notes-medical, 
                                        &.fa-leaf, 
                                        &.fa-baby {
                                            margin-top:6px;
                                        }
                                    }
                                }
                            }
                        }
                    }
        
                }
        
            }
        }
    } 


    @media (max-width: 379px) {
        &#mobile-form-container {
    
            .carousel {
                
                .carousel__slider {
                    ul {
                        
                        li {
                            padding-bottom: 338px !important;
                            
                            .carousel__inner-slide {
    
                                a.loan-type {
                                    width:47%;
                                    padding:12px 6px;
                                    font-size:15px;

                                    .svg-inline--fa {
    
                                        &.fa-car, 
                                        &.fa-map-marker-question, 
                                        &.fa-university, 
                                        &.fa-anchor, 
                                        &.fa-suitcase, 
                                        &.fa-rv, 
                                        &.fa-island-tropical, 
                                        &.fa-motorcycle, 
                                        &.fa-notes-medical, 
                                        &.fa-leaf, 
                                        &.fa-baby {
                                            margin-top:6px;
                                        }
                                    }
                                }
                            }
                        }
                    }
        
                }
        
            }
        }
    } 
`;

export default MobileFormWrapper;