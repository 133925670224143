import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { ConfigContext } from '../ConfigProvider/ConfigProvider';
import DoNotSellContentWrapper from './donotsellcontent.style';

class DoNotSellContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { company } = this.context;
    return (
      <DoNotSellContentWrapper className='donotsell-content'>
        <Container>
          <h1>Do not sell my personal information</h1>
          <p>
            {`These disclosures are required by the California Consumer Privacy Act (“CCPA”) and
            applies only to users who are California Residence and to “personal information” that is
            subject to the CCPA. California Residence with disabilities may access this notice by
            contacting us at privacy@${company.toLowerCase()} and requesting assistance.`}
          </p>
          <h2>Personal Information Sales Opt-Out and Opt-In Rights</h2>
          <p>
            Consumers who opt-in to personal information sales may opt-out of future sales at any
            time.
          </p>
          <p>
            To exercise the right to opt-out, you (or your authorized representative) may submit a
            request to us by visiting the following our webpage and sending us a message.
          </p>
          <p>
            Once you make an opt-out request, we will wait at least twelve (12) months before asking
            you to reauthorize personal information sales. However, you may change your mind and opt
            back in to personal information sales at any time by visiting our website and sending us
            a message or resubmitting your information.
          </p>
          <p>
            We will only use personal information provided in an opt-out request to review and
            comply with the request.
          </p>
          <p>
            {`We collect from consumers the following categories of personal information about
          California consumers: `}
          </p>
          <ul>
            <li>Personal and online identifiers, such as name and email address;</li>
            <li>
              Categories of information described in Section 1798.80(e) of the California Civil
              Code, such as telephone number;
            </li>
            <li>
              Internet or other electronic network activity information, such interactions with our
              website; and
            </li>
            <li>Professional or employment-related information.</li>
          </ul>
          <p>
            We use and disclose the personal information we collect for our commercial and business
            purposes, as further described in our
            <Link to='/privacy-policy/'> Privacy Policy </Link>
            and
            <Link to='/advertiser-disclosure/'> Advertising Disclosures. </Link>
            Our commercial purposes include advertising, marketing and matching services with 3rd
            party providers (link to 3rd party providers), while business purposes include, auditing
            related to our interactions with you; legal compliance; detecting and protecting against
            security incidents, fraud, and illegal activity; debugging; and performing services.
          </p>
          <p>
            While we may disclose all categories of personal information we collect to our service
            providers, we do not, and will not, sell personal information under the CCPA.
          </p>
          <p>
            To opt out, please contact us at
            <a href={`mailto:californiaprivacyoptout@${company.toLowerCase()}`}>
              {`californiaprivacyoptout@${company.toLowerCase()}`}
            </a>
          </p>
          <p>
            For all requests, you must put the statement “California Shine the Light Privacy
            Request” in the body of your request, as well as your original information you provided
            to include your name, phone number, email address, city, state, and zip code. Please
            note that we are not responsible for requests that are not labeled or sent properly, or
            that do not have complete information.
          </p>
          <p>
            To verify your request, we will compare the information you provide in your request to
            any information we maintain about you. Note that we may need to request additional
            information from you to verify your identity or understand the scope of your request,
            although you will not be required to create an account with us to submit a request or
            have it fulfilled.
          </p>
          <p>
            You may designate an authorized agent to make a CCPA request on your behalf by
            designating such an agent in writing or through a power of attorney. We will require the
            agent to provide us with proof that you have authorized the agent to make the CCPA
            request on your behalf prior to accepting a request from the agent.
          </p>
          <p>
            If you ask us to delete certain information, you may no longer be able to access or use
            the Services.
          </p>
        </Container>
      </DoNotSellContentWrapper>
    );
  }
}

DoNotSellContent.contextType = ConfigContext;

export default DoNotSellContent;
