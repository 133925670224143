import React from 'react';
import { Container } from 'semantic-ui-react';

const CanadaTerms = ({ company }) => (
  <Container>
    <h1>Terms & Conditions</h1>
    <p>
      This website (the “Site”) and the services offered through this Site are provided by Debt.ca
      (“Debt.ca“, “we“, “us” or “our“) for the use of individual users. All rights not expressly
      granted to users under these Terms are hereby expressly reserved by Debt.ca, and any
      additional uses of the Site or its contents require our prior written permission. For further
      information please contact support@debt.ca.
    </p>
    <p>
      These terms and conditions (“Terms“) govern your (“user“, “you” or “your“) use of the Site and
      our services, as well as any content downloaded from or accessed through the Site, and
      constitute a legal contract between you and Debt.ca. Please read these Terms in their entirety
      because your use of the Site, other than to review these Terms, signifies your consent to be
      legally bound by and to comply with these Terms.
    </p>
    <p>
      We reserve the right to change the Terms and Conditions at any time. At any time without
      notice or liability, and for any reason, we may update, change, suspend or terminate any
      aspect of these Terms or the Site. Please consult these Terms each time that you use the Site.
      Your continued use of the Site for 15 days or more following the posting of changes to these
      Terms will mean that you accept and agree to those changes. Notwithstanding the above, unless
      otherwise required by law, any application that you submit to us will continue to be governed
      by the version of these Terms in effect at the time that the application is submitted.
    </p>
    <p>If you do not agree with these Terms, you may not access or use the Site or our services.</p>
    <h3>
      <strong>LIMITED LICENSE</strong>
    </h3>
    <p>
      Subject to your compliance with these Terms, Debt.ca grants you a limited, personal,
      non-exclusive, non-transferable license to use the Site and our Content (as defined below)
      solely for the purpose of viewing, storing, processing and downloading information for
      personal use in connection with your use of our services.
    </p>
    <p>
      Except as expressly permitted in these Terms, you agree not to reproduce, make derivative
      works of, retransmit, distribute, sell, publish, sublicense, commercialize, communicate,
      broadcast or otherwise make available the Site or any Content, or other information and
      materials obtained through or in connection with the use of the Site, including without
      limitation, by caching, framing, deep-linking or similar means.
    </p>
    <h3>
      <strong>PRIVACY</strong>
    </h3>
    <p>
      Your use of the Site and our services, and our use of certain information about you, are
      subject to our privacy policy, which is incorporated by reference into these Terms. Please
      read the Privacy Policy carefully prior to using the Site and our services. Through your use
      of the Site or our services you agree to the collection, storage and use of your information
      in accordance with the Privacy Policy. In case of any inconsistency between these Terms and
      any terms set out in the Privacy Policy, the terms of the Privacy Policy will prevail.
    </p>
    <h3>
      <strong>RIGHTS TO UNSOLICITED COMMENTS AND IDEAS</strong>
    </h3>
    <p>
      All information, data, text, graphics, images, video, messages, ideas, reviews, opinions,
      questions, suggestions and other materials (“Content“) that are posted, e-mailed, transmitted,
      uploaded or otherwise submitted through your use of the Site or our services are submitted at
      your own risk and are your sole responsibility. If you are posting Content belonging to or
      generated by or on behalf of a third party, you warrant that you are authorized to act on
      behalf of that third party and to bind them to these Terms. You should not submit any Content
      that may cause harm or injury to any party. Do not publicly disclose any personal information
      about yourself or others.
    </p>
    <p>
      Except in the case of any Feedback (as defined below), you or a third party licensor retain
      all intellectual property rights in any Content that you submit. By submitting any Content,
      whether on your own behalf or on behalf of another party, you hereby grant to us a
      royalty-free, worldwide, transferable, non-exclusive right and license to use, reproduce,
      modify, edit, adapt, publish, translate, create derivative works from, distribute,
      disseminate, perform, transmit and display such Content (in whole or part) in any form, media,
      or technology now known or later developed for the full term of any rights that may exist in
      such Content, without compensation or obligation to you or any other party. The rights granted
      hereunder may be freely assigned or sub-licensed by us to any party.
    </p>
    <p>
      If you submit any ideas, comments or other feedback (“Feedback“) to us regarding the Site or
      our services, you agree that such Feedback (including all rights, title and interest therein)
      automatically become our property, and you forfeit all rights that you may have in such
      Feedback. For greater certainty, you agree that Debt.ca and its affiliated companies may use
      and exploit any Feedback in any way at their discretion, without compensation or obligation to
      you or to any other party. You represent and warrant that you have the necessary right to
      grant us the rights you are granting in respect of any Feedback you provide and you hereby
      waive all “moral rights” in and to such Feedback.
    </p>
    <h3>
      <strong>USER CONDUCT</strong>
    </h3>
    <p>
      You agree that you are responsible for your own conduct when using the Site and our services.
      Misuse or unauthorized use of the Site, its contents, Debt.ca’ services, network and systems,
      or of any information, forms, images or other materials on the Site is strictly prohibited and
      Debt.ca reserves the right, at its sole discretion and without liability or prejudice to any
      other remedies that it may have, to immediately suspend or terminate access by any user who is
      using, or who Debt.ca reasonably believes is using the Site or its services in violation of
      these Terms. You agree that you will use the Site (including contents) and the services only
      in ways that are legal, proper and in accordance with these Terms, accepted Internet protocol,
      and applicable rules, policies and guidelines. Without limiting the generality of the
      foregoing, by using the Site, you agree not to:
    </p>
    <ul>
      <li>
        use the Site, its contents or our services for any commercial activities not authorized by
        Debt.ca, including for advertising, soliciting or otherwise promoting the sale or other
        distribution of products or services;
      </li>
      <br />
      <li>
        interfere or attempt to interfere with, or obtain unauthorized access to, Debt.ca’ or any
        third party content providers’ Content, data, computers, systems or system security;
      </li>
      <br />
      <li>
        upload, post, distribute, e-mail or otherwise publish or make available any unsolicited or
        unauthorized advertising, promotional materials, “junk mail,” “spam,” “chain letters,”
        “pyramid schemes,” or any other form of unauthorized commercial communication, or to “hack”
        or “phish” us or other users;
      </li>
      <br />
      <li>
        solicit or otherwise attempt to collect, discover, store or disseminate passwords,
        personally identifying information, or other non-public information from Debt.ca or other
        users;
      </li>
      <br />
      <li>
        submit false information on any application or otherwise attempt to defraud Debt.ca, its
        debt companies or other third party service providers;
      </li>
      <br />
      <li>
        defame, abuse, threaten or otherwise violate the legal rights of others, including rights
        regarding privacy and publicity;
      </li>
      <br />
      <li>
        download any file posted by someone else that you know, or should reasonably know, cannot be
        legally distributed;
      </li>
      <br />
      <li>
        impersonate anyone else, or create user accounts by automated means or under false
        pretences;
      </li>
      <br />
      <li>
        remove any copyright, trademark or other proprietary rights notices from our materials, or
        from materials created by others, without prior permission;
      </li>
      <br />
      <li>
        submit Content that falsely expresses or implies that it is approved or endorsed by us or
        others;
      </li>
      <br />
      <li>
        upload, post, distribute, e-mail or otherwise publish or make available any Content that you
        know to be inaccurate, or that is illegal, libelous, defamatory, obscene, harmful, vulgar,
        sexually explicit, threatening, tortuous, harassing, abusive, invasive of another user’s
        privacy, hateful, racially or ethnically objectionable, which promotes physical harm or
        injury against any person or group, or which violates any obligations that you or any other
        party has pursuant to contractual or fiduciary relationships;
      </li>
      <br />
      <li>
        upload, post, distribute, e-mail or otherwise publish or make available any material that
        contains software viruses or any other computer code, files or programs designed to
        interfere with, destroy or limit the functionality of any computer software or system,
        website, application, telecommunications, or other equipment;
      </li>
      <br />
      <li>
        otherwise use the Site or our services in a manner that could, in Debt.ca’ opinion,
        adversely affect the ability of other users to use the Site, any content, our services, the
        Internet, or any sites or services supplied by our third party partners.
      </li>
    </ul>
    <p>
      We may, but are under no obligation to, pre-screen or monitor any Content other than our own.
      We reserve the right, but do not assume the obligation, to remove postings, and/or to ban
      users who violate these Terms from using the Site or our services in addition to pursuing any
      other rights or remedies available at law. Except where prohibited by law, we also reserve the
      right to reveal users’ identities (or any other information that we may know about users,
      including IP address(es) in the event of a complaint or legal action, including a request by
      law enforcement authorities. Debt.ca assumes no liability or responsibility for any
      unauthorized modifications to the Site, or for any information posted, uploaded or otherwise
      added to the Site by any party other than Debt.ca.
    </p>
    <p>
      You understand that by using the Site or our services, you may be exposed to Content that is
      incomplete, inaccurate, high risk, unlawful, offensive or objectionable. Under no
      circumstances will we be liable in any way for any loss, damage or injury (including, but not
      limited to, death or economic loss) related to, or arising from the use or loss of, any
      Content (whether yours, ours or someone else’s) including, but not limited to, as a result of
      any errors or omissions in any Content or on the Site.
    </p>
    <h3>
      <strong>THIRD PARTY CONTENT AND DISCLOSURE</strong>
    </h3>
    <p>
      Debt.ca provides the Site to you as a service, which allows you to receive information on
      various debt programs. You understand and agree that the submission of an application to us is
      not an application for a debt program. Debt.ca does not provide or facilitate a debt program,
      nor does it provide legal advice or offer credit repair services. If you require legal advice,
      you will need to consult with a licensed attorney. Further, you agree that any debt program or
      financing will be subject to separate terms and conditions to be agreed between you and the
      third party(ies) providing the debt program or financing.
    </p>
    <p>
      Debt.ca is merely a distributor, and not a publisher, of any services, advertisements or other
      content offered or supplied by third parties that is accessed via the Site or otherwise
      through your use of our services. Debt.ca does not have administrative or other control over
      such third party products and services, offers no warranties or representations, and accepts
      no responsibility or liability in respect of any third party products or services, or a user’s
      use of them. In any transactions or proposed transactions with third parties, including debt
      companies and credit organizations who you have come in contact with through the use of the
      Site and/or our services, you agree that Debt.ca is not the seller or provider. Your agreement
      to purchase any products or services from a third party or to otherwise enter into
      negotiations or transactions with a third party is solely between you and the third party, and
      not us. Under no circumstances will Debt.ca be responsible or liable for any loss or damage of
      any kind incurred as a result of, or in connection with, any discussions, negotiations,
      correspondence, agreements or other business dealings by you with any third parties, including
      any debt companies, credit organizations or advertisers found on or through the use of the
      Site, and including payment for and delivery of related goods and services.
    </p>
    <p>
      Further, you acknowledge and agree that Debt.ca does not guarantee the products or services of
      any of the persons or organizations to whom we provide your personal information, or that any
      person or organization will offer or provide you with products or services at any rate or at
      all. All products and services of the providers presented on the Debt.ca Site that are
      described, made available or provided on the site may be changed or withdrawn at any time
      without notice and are subject to the terms and conditions of the agreements governing their
      use that are in force at the time of the provision or delivery of the product or service. The
      information, material and content provided in the pages of the Site by a service provider,
      including pricing information, is believed to be reliable when added to the Site, but there is
      no guarantee that it is accurate, complete or current at all times. Please verify all savings
      estimates, fees and other program information directly with a debt specialist from your
      product vendor or service provider. Without limiting the generality of the foregoing, the
      information may include technical inaccuracies or typographical errors, and the service
      providers and their officers, directors, employees and agents have no obligation to update the
      information. The information or the Site may be changed, withdrawn or terminated at any time
      without notice. You understand that providers may keep your request information and any other
      information provided by or through Debt.ca or received by them in the processing of your
      request, whether or not you are qualified for the product or service with them or if you
      acquire a product or service with them. You agree to notify any particular provider directly
      if you no longer want to receive communications from them.
    </p>
    <p>
      The Site may contain hyperlinks to other web sites (“Linked Sites“) that have been developed
      by advertisers and other third parties, which are not under the control of Debt.ca. Debt.ca
      provides hyperlinks to the Linked Sites as a convenience only, and their inclusion does not
      imply warranties, representations, endorsements, approvals, verification or investigation by
      Debt.ca or its agents of the Linked Sites, or of any products or services offered on or
      through the Linked Sites. Further, Debt.ca is not responsible for the contents of the Linked
      Sites, and is not acting as a publisher or disseminator of information or materials contained
      on any Linked Site or any hyperlink contained on a Linked Site. You acknowledge that the use
      of Linked Sites may be subject to terms and conditions contained on those Linked Sites, and
      you agree that you are solely responsible for your use of any Linked Site.
    </p>
    <h3>
      <strong>INTELLECTUAL PROPERTY</strong>
    </h3>
    <p>
      The Site, our services, and any Content and other information and materials created or
      supplied by us, and the selection and arrangement thereof, are owned by us (or our third party
      licensors) and are protected by Canadian and international copyright and other intellectual
      property laws and treaty provisions.
    </p>
    <p>
      “DEBT.CA” and other trademarks and design marks, trade dress, domain names, service names,
      logos and associated designs used in connection with the Site or our services are trademarks
      and/or registered trademarks of Debt.ca and/or its affiliates, licensors, or related
      companies, and may not be used, copied, or imitated, in whole or in part, without our prior
      written permission. All other products, brand and company names and logos used or mentioned on
      the Site, any listings or other Content accessed through the Site or the use of our services,
      may be the trademarks (registered or unregistered) of their respective owners. The use of any
      trademark without the express written consent of the owner of the trademark is strictly
      prohibited. You will not remove, alter or conceal any trademark or other proprietary rights
      notices incorporated into or accompanying the Site, any of our Content or any other
      information or materials accessed through the Site or the use of our services.
    </p>
    <h3>
      <strong>INDEMNITY</strong>
    </h3>
    <p>
      You agree to defend, indemnify and hold us, our affiliates and licensors, and each of our
      respective officers, partners, directors, employees and agents harmless, from and against any
      and all claims, actions or demands, including without limitation reasonable legal and
      accounting fees, resulting from or related to: (a) your breach of these Terms, including the
      Privacy Policy which is incorporated into these Terms by reference; (b) your access to or use
      of the Site, or our or any other party’s Content; (c) your submission of any Content; (d) your
      misuse of our services; (e) your use of or reliance upon, or publication, communication or
      distribution of any materials from any third party source; or (f) your violation of any other
      party’s rights including, without limitation, any intellectual property, privacy or
      proprietary right, or obligation of confidentiality. You shall use your best efforts to
      cooperate with us in the defense of any claim. We reserve the right, at our own expense, to
      assume the exclusive defense and control of any matter otherwise subject to indemnification by
      you.
    </p>
    <h3>
      <strong>DISCLAIMER OF WARRANTIES</strong>
    </h3>
    <p>You expressly understand and agree as follows:</p>
    <ul style={{ listStyle: 'none' }}>
      <li>
        A. The site and our services are provided to you for your personal use in accordance with
        these terms. your use of the site, including any links or content, and our services is at
        your sole risk. to the maximum extent permitted by applicable law, the site, our services,
        our content, and any other information and materials that we make available to you is
        provided on an “as is” and “as available” basis. we expressly disclaim all warranties or
        conditions of any kind, whether express, implied or statutory, including, but not limited to
        implied warranties of merchantability, merchantable quality, fitness for a particular
        purpose, non-infringement, or that a particular result (or any result) will be attained
        through the use of our services.
      </li>
      <br />
      <li>
        B. Without limiting the generality of the foregoing, to the maximum extent permitted by
        applicable law, we make no warranty or give any condition that: (i) the site, our services,
        or any content, linked or related websites or other materials will meet your expectations or
        satisfy your requirements; (ii) debt programs, or any other products or services, will be
        available to you; (iii) the use of the site or any content, linked or related websites or
        other materials will be uninterrupted, timely, secure, or error-free; (iv) the results that
        may be obtained from the use of the site, our services, or any content will be complete,
        accurate, reliable or useful; (v) the quality of any products, services, programs,
        information, or other material accessed by you through your use of the site or our services
        will meet your expectations; and (vi) any errors in the site, content or other materials
        will be corrected.
      </li>
      <br />
      <li>
        C. Any content or other material downloaded or otherwise accessed or obtained through the
        use of the site is obtained and used at your own discretion and risk, and you will be solely
        responsible for any harm or damage, including (but not limited to) damage to your computer
        system or loss of data, that results from the download of any such content or material.
        Debt.ca expressly disclaims responsibility for any damage to, or viruses that may infect,
        your software, computer equipment (including mobile devices) or other property as a result
        of your access to, use, or browsing of the site or associated websites, or from using or
        downloading any information, data, text, forms, software, photographs, graphics, images,
        messages, ideas or other content.
      </li>
      <br />
      <li>
        D. To the maximum extent permitted by applicable law, you agree that no advice or
        information, whether oral or written, obtained by you from us, through your use of the site
        or our services, shall create any warranty or other liability not expressly stated in these
        terms.
      </li>
    </ul>
    <p>
      You acknowledge and agree that if you are dissatisfied with the site or our services, or with
      any results obtained through their use, your only remedy is to discontinue use of the site and
      our services.
    </p>
    <h3>
      <strong>LIMITATION OF LIABILITY</strong>
    </h3>
    <p>
      You expressly understand and agree that we will not be liable for any direct, indirect,
      incidental, special, consequential or exemplary damages of any kind, including but not limited
      to, damages for loss of profits, goodwill, use, data or other intangible losses (even if we
      have been advised of the possibility of such damages), resulting from: (i) the use or the
      inability to use or access the site, our systems, any content, or any materials, products,
      services, offerings or information obtained through the use of the site or our services; (ii)
      any errors or omissions in the site or any content; (iii) the suspension or termination of
      your ability to access the site; (iv) any interruption or cessation of transmission to or from
      our site or failure of our systems; (v) the cost of procurement of substitute goods and
      services; (vi) unauthorized access to or alteration of our systems or your transmissions, data
      and/or personal or financial information; (vii) statements or conduct of any third party using
      the site; (viii) any bugs, viruses or the like that are transmitted to or through our site by
      any third party; or (ix) any other matter or event relating to your or any other party’s use
      of the site or our services. your sole remedy under these terms shall be to discontinue use of
      the site and our services.
    </p>
    <p>
      You expressly acknowledge that we have entered into this agreement with you, and have and will
      make the site and our services available to you in reliance upon the limitations and
      exclusions of liability and the disclaimers set forth in these terms, and that the same form
      an essential basis of the bargain between you and us. you expressly agree that the limitations
      and exclusions of liability and the disclaimers set forth in these terms will survive, and
      continue to apply in the case of, fundamental breach or breaches, the failure of essential
      purpose of contract, the failure of any exclusive remedy, or termination of the agreement
      between you and us.
    </p>
    <p>
      Some jurisdictions do not allow the exclusion of certain warranties or the limitation or
      exclusion of liability for certain damages and, accordingly, some or all of the above
      limitations may not apply to you. in such jurisdictions, you agree that our liability shall be
      limited to the greatest extent permitted by applicable law.
    </p>
    <h3>
      <strong>TERMINATION</strong>
    </h3>
    <p>
      You may discontinue your use of the Site and/or our services at any time, for any reason or
      for no reason. Any program agreements that you may have entered into as a result of using our
      services will continue in accordance with their terms.
    </p>
    <p>
      In addition to any other termination rights that we may have, we reserve the right to modify,
      suspend, discontinue or terminate the Site or any party’s ability to access or use the Site,
      our services, our materials or other Content, at any time and for any reason (or no reason),
      including any breach by you of these Terms.
    </p>
    <p>
      Upon termination, you will have no further right to use the Site or our services, and any
      rights and licenses granted to you under these Terms will terminate. Termination or other
      discontinuation of your use of the Site or our services will not relieve you of any
      obligations arising prior to termination regarding the use and protection of intellectual
      property or confidential information, ownership, or indemnification nor will it relieve you of
      any liability for any breach of these Terms. Upon termination (subject always to applicable
      laws) we will have the right, but not the obligation, to retain any Content or other
      information that you have submitted.
    </p>
    <h3>
      <strong>GOVERNING LAW AND JURISDICTION</strong>
    </h3>
    <p>
      The Site originates, and is owned by parties having operations in the Province of Saskatchewan
      and elsewhere in Canada. These Terms will be interpreted, construed and governed by the laws
      in force in the Province of Saskatchewan, and the federal laws of Canada applicable therein,
      without reference to its conflicts of laws principles. Each party hereby agrees to submit to
      the exclusive jurisdiction of the courts of the Province of Saskatchewan sitting at Regina,
      and to waive any objections based upon venue. You agree that any claim or action brought by
      you in connection with these Terms or your use of the Site or our services shall be brought
      within twelve (12) months of the first occurrence of the loss or damage on which the claim is
      based.
    </p>
    <h3>
      <strong>AUTHORIZED USERS</strong>
    </h3>
    <p>
      Use of this Site and our services are available only to residents of Canada who have attained
      the age of majority in their province of residence. Any products and service offered through
      the Site are offered only in jurisdictions where they may be legally offered, and are offered
      only to persons to whom it is lawful to make a solicitation. You agree to comply with all
      applicable laws, rules and regulations governing your use of the Site and our services.
    </p>
    <h3>
      <strong>NO ADVICE</strong>
    </h3>
    <p>
      Debt.ca does not provide specific financial, investment, accounting, tax or legal advice of
      any kind. The materials on this Site are not intended to be, or should they be interpreted as,
      legal opinion or advice, and should not be acted or relied upon in that regard without seeking
      the advice of a professional. Your advisor can help to ensure that your own circumstances have
      been properly considered and any action is taken on the latest available information. You
      should apply your own judgment in making use of any products, services or content provided on
      or through the Site, including, without limitation, the use of any information contained
      therein as the basis for any conclusions. You are responsible for your own research and
      decisions regarding the purchase of any product or service.
    </p>
    <h3>
      <strong>MISC</strong>
    </h3>
    <p>
      These Terms contain the entire agreement between you and us governing your use of the Site and
      our services. The legal agreement created by your acceptance of these Terms is personal, and
      cannot be transferred or assigned by you. We may assign this agreement and/or any of our
      rights or obligations under these Terms at any time.
    </p>
    <p>
      Our failure to exercise or enforce any right or remedy under these Terms shall not constitute
      a waiver of such right or remedy.
    </p>
    <p>
      No use of the Site, any Content or our services shall create or be deemed to create any
      partnership, joint venture, agency, franchise or other business relationship, even if your use
      is for a commercial purpose which has been authorized by us. You and Debt.ca shall, at all
      times, be and remain independent contractors.
    </p>
    <p>
      The provisions of these Terms are severable. If any individual term or condition is held to be
      invalid, unenforceable or contrary to applicable law, such provision shall be construed,
      limited, altered or deleted, as necessary, to eliminate the invalidity, unenforceability or
      conflict with applicable law while endeavoring to preserve the intention of the provision, and
      all other terms and conditions shall remain in full force and effect.
    </p>
    <p>
      Debt.ca may communicate with you by email or by posting notices on the Site You may be asked
      to provide your consent to receive other communications. You further agree that these Terms,
      and all correspondence and documentation relating to these Terms and your use of the Site,
      shall be written in the English language. Vous acceptez que ces terms d’usage, ainsi que toute
      la correspondence et la documentation relative à ces termes d’usage, soient rédigées en langue
      anglaise.
    </p>
    <p>
      If you wish to contact us with any questions, comments or concerns regarding these Terms, the
      Site or our services, or to provide any notice pursuant to these Terms, please contact us at
      support@debt.ca.
    </p>
    <i>The Terms and Conditions was last updated on March 29, 2016.</i>
  </Container>
);

export default CanadaTerms;
