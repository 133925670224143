import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';

const CanadaPrivacyPolicy = () => (
  <Container>
    <h1>Privacy Policy</h1>
    <p>
      This Privacy Policy (“Policy”) describes the personal information that Debt.ca (“Debt.ca”,
      “we”, “us” or “our”) collects from or about you when you use our website and our services, how
      we use that information, and to whom we disclose it. “Personal information” means any data
      that identifies a user as an individual or is capable of doing so, and does not include
      general, statistical or aggregated information. Examples of personal information that we may
      collect, use and disclose include your contact information, your financial information and
      other information that you may provide to us or that we may obtain from others in conjunction
      with your use of our website and services.
    </p>
    <p>
      If you do not agree to our Privacy Policy or{' '}
      <Link to="/terms-conditions"> Terms and Conditions</Link>, you are not permitted to access and
      use this Website and you should cease such access and/or use immediately.
    </p>
    <h3>
      <strong>ACCOUNTABILITY AND COMPLIANCE</strong>
    </h3>
    <p>
      Debt.ca is responsible for personal information under our control. We have established
      policies and procedures to effectively safeguard any confidential personal information that we
      have on file or which we collect, and to deal with complaints and inquiries. We are committed
      to maintaining the accuracy, confidentiality and security of your personal information, and we
      will ensure that you have access to information regarding the policies and procedures that we
      use to manage your personal information.
    </p>
    <p>
      All questions or concerns regarding this Policy and our compliance with it should be directed
      in writing, and sent by email to privacy@debt.ca or by post to:
    </p>
    <address>
      Debt.ca
      <br />
      595 Burrard St
      <br />
      Vancouver BC V7X 1S8
      <br />
    </address>
    <br />
    <p>
      Every complaint regarding our compliance with this Policy will be investigated, and where a
      deficiency is found to exist, we will take appropriate measures to address it. This may
      include amending our policies and procedures as necessary. We will also cooperate with
      regulatory authorities to resolve any complaints that cannot be resolved between us and an
      individual user.
    </p>
    <h3>
      <strong>CONSENT</strong>
    </h3>
    <p>
      By using our website and services, you signify your agreement to the terms and conditions of
      this Policy and to our collection, use and disclosure of your personal information as set out
      herein. You may change or withdraw your consent to the collection, use or disclosure of your
      personal information at any time by contacting us in writing at either of the addresses listed
      above. In some circumstances, a change or withdrawal of consent may affect your ability to use
      our website or services.
    </p>
    <h3>
      <strong>COLLECTION OF INFORMATION</strong>
    </h3>
    <p>
      We collect personal information only to the extent that it is necessary for the purposes set
      out below (see: Purpose – Why We Collect, Use and Disclose Information). Personal information
      that is collected may include, but is not limited to:
    </p>
    <ul>
      <li>
        information provided by you during the application process, including your contact
        information (such as name, email address and telephone number), debt amount, credit rating
        range, homeowner status, property value, mortgage balance, postal code;
      </li>
      <br />
      <li>
        information provided by third parties that we connect you with through the application
        process, such as debt companies, credit repair providers and financing entities, relating to
        your application and any resulting transactions that you are involved in; and
      </li>
      <br />
      <li>
        information about your internet browsing activities and browser (see: Cookies and Web
        Beacons).
      </li>
    </ul>
    <p>
      Subject to any legal or accounting requirements, we will retain personal information only as
      long as necessary to fulfill the purposes for which it was collected. Personal information
      that is no longer required will be destroyed, erased or made anonymous, although copies of
      deleted information may continue to exist on back-up media.
    </p>
    <p>
      Users may visit our website without telling us who they are, or revealing any information
      about themselves. However, like many organizations’ websites, our web server automatically
      logs certain non-identifying information related to a user’s visit to the website, including
      the Internet Protocol (IP) address of the user’s computer, the user’s Internet service
      provider (ISP), the type and version of the browser that the user is using, the date and time
      the user accessed our website, the Internet address of the website from which the user linked
      directly to our site, the operating system that the user is using, and the pages of the
      website that the user has visited. We make no attempt to link this information with the
      identity of individuals visiting our website without express permission. We may, however,
      review server logs and anonymous traffic for system administration and security purposes, for
      example to detect intrusions into our network, for planning and improving web services, and to
      monitor and compile statistics about website usage. The possibility therefore exists that
      server log data, which contains users’ IP addresses, could in instances of criminal
      malfeasance be used to trace and identify individuals. In such instances, we may share raw
      data logs with the appropriate authorities for the purpose of investigating security breaches.
    </p>
    <p>
      If you provide comments or other feedback to us, you agree that such comments or other
      feedback become the property of Debt.ca, and we may use and disclose them for any purpose
      provided that we do not associate them with your personally identifiable information.
    </p>
    <h3>
      <strong>COOKIES AND WEB BEACONS</strong>
    </h3>
    <p>
      A “cookie” is a small piece of information stored on your computer by a web page. It is used
      to identify you to the web server. It tells the server who you are when you return to a page
      on the same website. Your browser will only send a cookie back to the domain that originally
      sent it to you. A cookie cannot run any programs, deliver any viruses, or send back
      information about your system.
    </p>
    <p>
      We may use cookies to determine your access privileges on our websites, to complete and
      support a current activity, and to track website usage.
    </p>
    <p>
      Most web browsers automatically accept cookies, but if you do not wish to have cookies on your
      system, you should adjust your browser settings to decline them or to alert you when cookies
      are being sent. If you decline cookies, you will still be able to use our website and
      services, but your ability to access certain features and functions may be affected.
    </p>
    <p>
      Some of our web pages contain an invisible electronic image known as a “web beacon.” Web
      beacons are similar in function to cookies, but allow websites on different domains to share
      information. We may use web beacons to transfer your subscription access information between
      our websites and domains. Web beacons do not transmit any personal information that we collect
      to any websites that are not owned by Debt.ca or its affiliates.
    </p>
    <p>
      We may also employ third party remarketing services which allows us to reconnect with our past
      website visitors. Third party marketing service providers, like Google, will display our ads
      on websites on the Internet. Google, and other remarketing services use cookies to track
      activity of users. You can opt-out of Google’s service by visiting the Ads Preferences
      Manager. You can also visit the National Advertising Initiative and opt-out from some or all
      of the participating remarketing service providers.
    </p>
    <h3>
      <strong>USE AND DISCLOSURE OF INFORMATION</strong>
    </h3>
    <p>
      We will identify the purposes for which we collect personal information before or when we
      request the information. We will not collect personal information which is not necessary and,
      except as specified below, will not use or disclose personal information for any purpose other
      than the purpose(s) for which it was collected without first obtaining your consent. The
      information that we collect is used and disclosed only for business purposes. These include:
    </p>
    <ul>
      <li>to process, track and communicate with you about your application;</li>
      <li>
        to connect you with debt companies, credit repair providers or financing entities that may
        be able to assist you in reducing your debt payments;
      </li>
      <li>
        to establish, maintain and manage business relations with you so that we may provide you
        with the information, products or services that you request;
      </li>
      <li>
        internal business purposes, such as administering or improving our website or services;
      </li>
      <li>to perform market research and conduct polls and surveys;</li>
      <li>
        by accepting the terms of this Privacy Policy, you also consent to receiving e-mails about
        additional products and services from us or any of our affiliates.
      </li>
      <li>
        to provide you with information, solicitations and promotional materials regarding Debt.ca
        and our affiliates, and products or services offered by Debt.ca
      </li>
      <li>
        to sell or otherwise provide your personal information to our affiliates so that subject to
        your consent they may contact you about products or services directly or indirectly related
        to the debt industry, including debt relief companies, credit repair products and services,
        personal loan financing, and car financing;
      </li>
      <li>
        to protect us against error, fraud, theft or damage to our goods, our business or our
        property;
      </li>
      <li>
        to comply with any legal, accounting and regulatory requirements, including reporting
        requirements, applicable laws, and any search warrants, subpoenas or court orders; and
      </li>
      <li>any other reasonable purpose for which you provide consent.</li>
    </ul>
    <p>
      We may collect, use or disclose your personal information without your knowledge or consent
      where we are permitted or required to do so by applicable law, government request or court
      order, or based on our good faith belief that it is necessary to do so in order to comply with
      such law, request or court order, or to protect our assets, the users of our website, or the
      public.
    </p>
    <h3>
      <strong>THIRD PARTY DISCLOSURE</strong>
    </h3>
    <p>
      We may disclose your personal information in response to requests from government agencies,
      law enforcement authorities and regulators, or to satisfy legal or regulatory requirements. We
      may also disclose your personal information when we buy a business or sell all or part of our
      business.
    </p>
    <p>
      Subject to your opting out below, we may sell or otherwise provide your personal information
      to our affiliates and non-affiliated third parties, including debt companies, other wholesale
      lead providers and other persons or organizations that offer products or services directly or
      indirectly related to the debt industry, including debt relief companies, credit repair
      products and services, personal loan financing, and car financing. You acknowledge and agree
      that our affiliates and the non-affiliated third parties to whom we sell or provide your
      personal information may, in connection with the purpose for which the information was
      disclosed, conduct searches in relation to your personal information, including credit checks,
      or contact you to request additional information. We will use appropriate contractual or other
      means to require our affiliates and non-affiliated third parties to whom your personal
      information is disclosed to provide a comparable level of protection while the information is
      being used by them and to refrain from further disclosing your personal information without
      your consent, except as provided in this Policy.
    </p>
    <p>
      You may opt out of receiving communications from us at any time by following the opt-out
      instructions in the email communication, by emailing us at privacy@debt.ca
    </p>
    <p>
      You further acknowledge and agree that, in the course of our supply of services to you, we may
      delegate our authority to collect, access, use, and disseminate your information to third
      party subcontractors. Third party subcontractors may include web hosts, payment processors,
      delivery and logistics providers, social network integrators, and membership vendors. If we
      transfer any personal information to a third party subcontractor, we will provide the
      subcontractors only with the information needed to perform the subcontracted service, and will
      use appropriate contractual or other means to provide a comparable level of protection while
      the information is being used by them. However, you agree not to hold us liable for the
      actions of any third party subcontractor, even if we would normally be held vicariously liable
      for their actions, and understand that you must take legal action against them directly should
      they commit any tort or other actionable wrong against you.
    </p>
    <h3>
      <strong>SECURITY AND ACCURACY OF INFORMATION</strong>
    </h3>
    <p>
      We have implemented reasonable physical, organizational, contractual and technological
      security measures to protect personal information in our possession or under our control from
      loss or theft, and from unauthorized access, disclosure, copying, use or modification,
      regardless of the format in which the information is held. The safeguards applied will depend
      on the sensitivity of the personal information, with the highest level of protection given to
      the most sensitive personal information. We use user IDs, passwords, and encryption
      technology, maintain our servers and paper files in locked premises, and restrict the
      employees and contractors who have access to personal information to those having a “need to
      know” and who are bound by confidentiality obligations in order to ensure that information is
      handled and stored in a confidential and secure manner. When destroying personal information,
      we delete electronically stored personal information and shred any tangible materials
      containing personal information. While we will endeavour to destroy all copies of personal
      information, you acknowledge that deleted information may continue to exist on back-up media
      but will not be used unless permitted by law.
    </p>
    <p>
      We will continually review and update our security policies and controls as technology
      evolves. However, no security technology can be guaranteed to be failsafe. Using the Internet
      or other public means of communication to collect and process personal data may involve the
      transmission of data on an international basis and across networks not owned and/or operated
      by us. Therefore, by accessing our website, using our services and/or communicating
      electronically with us, you acknowledge and agree to our processing of personal information in
      this way and agree that we are not responsible for any personal information which is lost, or
      which is altered, intercepted or stored by a third party without authorization.
    </p>
    <p>
      Debt.ca has a responsibility to ensure that all personal information contained in our records
      or which is disclosed to third parties for the purposes described above is accurate, complete
      and up-to-date. You may make a request in writing for access to your personal information. We
      will inform you of your personal information held by us, and provide an account of the use
      that has been made of the information, as well as identify any third parties to whom the
      information has been disclosed. You may have reasonable access to your personal information,
      and if you demonstrate the inaccuracy or incompleteness of personal information, the
      information will be amended as appropriate. You should advise us immediately if you discover
      inaccuracies in our data, if your personal information changes, or if you wish to have your
      information removed from our files. All notices and requests should be in writing and sent to
      one of the addresses set out above.
    </p>
    <h3>
      <strong>INTERNATIONAL TRANSFER AND STORAGE OF INFORMATION</strong>
    </h3>
    <p>
      You acknowledge and agree that your personal information may be transmitted, transferred,
      processed, and/or stored outside of Canada, including in the United States. We will use
      reasonable means to ensure that your information is protected, but cannot guarantee that the
      laws of any foreign jurisdiction will accord the same degree of protection as the laws of
      Canada. By submitting your personal information to us, you consent to the transmission,
      transfer, processing and/or storage of your personal information outside of Canada.
    </p>
    <h3>
      <strong>THIRD PARTY LINKS</strong>
    </h3>
    <p>
      Our website may contain optional links to advertiser and other third party Internet sites and
      services. You acknowledge that these third parties may collect data from users or their
      computers. The accessing and use of third party websites is at your own risk, and we cannot
      assume responsibility for the privacy practices, policies or actions of the third parties who
      operate those websites. This Policy applies only to the Debt.ca website, and we encourage you
      to review the privacy policies contained on each Internet site that you use.
    </p>
    <h3>
      <strong>MINORS</strong>
    </h3>
    <p>
      Minors (persons under the age of 18, although the age of majority may vary in other
      jurisdictions) are not eligible to use the website or services unsupervised, and we request
      that minors do not submit any personal information to us. If you are under the age of majority
      in your jurisdiction, you may only use the website or services in conjunction with and under
      the supervision of an adult. Debt.ca does not knowingly collect personal information from
      minors.
    </p>
    <h3>
      <strong>GOVERNING LAW</strong>
    </h3>
    <p>
      You agree that your access to and use of the Debt.ca website, including this Policy, and
      services is governed exclusively by the laws of the Province of Saskatchewan and the federal
      laws of Canada applicable therein (excluding conflicts or choice of laws principles).
    </p>
    <h3>
      <strong>CREDIT REPORT NOTICE</strong>
    </h3>
    <p>
      You acknowledge and agree that affiliates and non-affiliated third parties, including debt
      companies, and other persons or organizations to which we have disclosed your personal
      information in accordance with this Privacy Policy, may request a credit report for any
      legitimate purpose associated with your application [for credit, extending credit, modifying
      the terms of your credit agreement, or collection on your account.]
    </p>
    <i>
      This Policy was last updated on February 22, 2018. We reserve the right to change this Privacy
      Policy at any time.
    </i>
  </Container>
);

export default CanadaPrivacyPolicy;
